import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Bullet } from './assets/bullet.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as ArrowDiagonal } from './assets/arrow-diagonal.svg';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';
import avatar from './assets/avatar.png';
import { ReactComponent as Logo } from './assets/logo.svg';
import fox from './assets/fox.png';
import apps from './assets/apps.png';
import brands from './assets/brands.png';
import goDaddy from './assets/godaddy.png';
import ux from './assets/ux.png';
import motionVideo1 from './assets/motion-video-1.mp4';
import motionVideo2 from './assets/motion-video-2.mp4';
import motionVideo3 from './assets/motion-video-3.mp4';
import motionVideo4 from './assets/motion-video-4.mp4';
import mintImage1 from './assets/mint-image-1.jpg';
import goDaddyVideo1 from './assets/godaddy-video-1.mp4';
import uxImage1 from './assets/ux-image-1.png';
import uxImage2 from './assets/ux-image-2.png';
import uxImage3 from './assets/ux-image-3.png';
import uxImage4 from './assets/ux-image-4.png';
import uxImage5 from './assets/ux-image-5.png';
import uxImage6 from './assets/ux-image-6.png';
import uxImage7 from './assets/ux-image-7.png';
import uxImage8 from './assets/ux-image-8.png';
import uxImage9 from './assets/ux-image-9.png';
import uxImage10 from './assets/ux-image-10.png';
import uxImage11 from './assets/ux-image-11.png';
import uxImage12 from './assets/ux-image-12.png';
import uxImage13 from './assets/ux-image-13.png';
import uxImage14 from './assets/ux-image-14.png';
import uxImage15 from './assets/ux-image-15.png';
import uxImage16 from './assets/ux-image-16.png';
import uxImage17 from './assets/ux-image-17.png';
import uxImage18 from './assets/ux-image-18.png';
import uxImage19 from './assets/ux-image-19.png';
import uxImage20 from './assets/ux-image-20.png';
import uxImage21 from './assets/ux-image-21.png';
import uxImage22 from './assets/ux-image-22.png';
import uxImage23 from './assets/ux-image-23.png';
import uxImage24 from './assets/ux-image-24.png';
import uxImage25 from './assets/ux-image-25.png';
import uxImage26 from './assets/ux-image-26.png';
import uxImage27 from './assets/ux-image-27.png';
import uxImage28 from './assets/ux-image-28.png';
import styles from './App.module.css';

const modals = {
  mint: {
    heading: 'Mint Mobile',
    kpis: [
      '$1.35 Billion Acquisition',
      'Customer Retention',
      'ARPU',
    ],
    media: [
      mintImage1,
    ],
    paragraphs: [
      {
        description: `At Mint Mobile I am responsible for the website, activation flow, and logged-in customer experience. My team has tested and launched several successful features and programs like Mint Family, eSIM, 2FA, and Refer-A-Friend. We work closely with Brand, UXR, E-commerce, and Engineering to execute UX strategy that resulted in increased ARPU, customer retention, and ultimately the
$1.35 billion acquisition by T-Mobile.`,
      },
    ],
  },
  motion: {
    heading: 'Motion',
    paragraphs: [
      {
        description: `Micro interactions and animation can add the perfect coat of polish to a great user experience. Whether it’s indicating progress, providing system feedback, transitions, or simplify there to delight the user. Here are just a few examples of my motion work.`,
      },
    ],
    media: [
      motionVideo1,
      motionVideo2,
      motionVideo3,
      motionVideo4,
    ],
  },
  goDaddy: {
    heading: 'GoDaddy',
    kpis: [
      '$3M Annualized GCR',
      '13% Recommendation CTR',
      '6% Email Activations',
    ],
    paragraphs: [
      {
        title: 'The Problem',
        description: `Users were unable to differentiate between products, recommendations tested as too ad-like, and overall engagement was low. Customers were bouncing to other areas of the website.`,
      },
      {
        title: 'The Solution',
        description: `Users were unable to differentiate between products, recommendations tested as too ad-like, and overall engagement was low. Customers were bouncing to other areas of the website.`,
      },
    ],
    media: [
      goDaddyVideo1,
    ],
  },
  ux: {
    heading: 'UX Design',
    paragraphs: [
      {
        description: `I am an experienced UX and product design leader with DTC and B2B experience spanning several industries. A passion for finding that sweet spot where user and business goals intersect. Data-driven, solution-oriented, and action-biased. But more importantly, I have delivered experiences that fit seamlessly into every day life in an inclusive, accessible way.`,
      },
    ],
    media: [
      uxImage1,
      uxImage2,
      uxImage3,
      uxImage4,
      uxImage5,
      uxImage6,
      uxImage7,
      uxImage8,
      uxImage9,
      uxImage10,
      uxImage11,
      uxImage12,
      uxImage13,
      uxImage14,
      uxImage15,
      uxImage16,
      uxImage17,
      uxImage18,
      uxImage19,
      uxImage20,
      uxImage21,
      uxImage22,
      uxImage23,
      uxImage24,
      uxImage25,
      uxImage26,
      uxImage27,
      uxImage28,
    ],
  },
};

function App() {
  const [currentModal, setCurrentModal] = useState('mint');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(0);
  const videoRef = useRef();

  function closeModal() {
    setModalOpen(false);
    document.querySelector('body').classList.remove('modalOpen');
    setCurrentMedia(0);
  }

  function openModal(name) {
    setCurrentModal(name);
    setModalOpen(true);
    document.querySelector('body').classList.add('modalOpen');
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      const videoPromise = videoRef.current.play();
      videoPromise.catch(error => {});
    }
  }, [videoRef]);

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <Logo />
      </header>
      <main className={styles.main}>
        <div className={styles.content}>
          <section className={styles.marquee}>
            <div className={styles.textContainer}>
              <h1 className={styles.h1}>Taking brands<br/>to <span className={styles.accent}>space.</span></h1>
              <p className={styles.p}>Taking products to market and teams to new heights</p>
            </div>
            <div className={classNames(styles.avatarContainer, styles.centered, styles.hideOnMobile)}>
              <a className={styles.avatar} href="https://www.linkedin.com/in/randymarston/" target="_blank">
                <img className={styles.avatarImage} src={avatar} />
              </a>
            </div>
          </section>
          <section className={styles.tiles}>
            <div className={classNames(styles.tile, styles.mint)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>Mint Mobile</h3>
                  <p className={styles.p}>Impact</p>
                </div>
                <div className={styles.mediaContainer}>
                  <img className={styles.media} src={fox} />
                </div>
                <button className={styles.button} onClick={() => openModal('mint')} type="button">
                  <ArrowDiagonal />
                </button>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.experienceAndIndustries)}>
              <div className={classNames(styles.tile, styles.experience)}>
                <div className={styles.tileContent}>
                  <div className={styles.headingContainer}>
                    <h3 className={classNames(styles.h3,styles.stat)}>12+</h3>
                    <p className={styles.p}>Years of Experience</p>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.tile, styles.industries)}>
                <div className={styles.tileContent}>
                  <div className={styles.headingContainer}>
                    <h3 className={classNames(styles.h3,styles.stat)}>10+</h3>
                    <p className={styles.p}>Industries</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.apps)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>Native App</h3>
                  <p className={styles.p}>Examples</p>
                </div>
                <div className={styles.mediaContainer}>
                  <img className={styles.media} src={apps} />
                </div>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.motion)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>Motion</h3>
                  <p className={styles.p}>Examples</p>
                </div>
                <div className={styles.mediaContainer}>
                  <video autoPlay className={styles.media} loop playsInline muted ref={videoRef} src={motionVideo3} />
                </div>
                <button className={styles.button} onClick={() => openModal('motion')} type="button">
                  <ArrowDiagonal />
                </button>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.brands)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>Brands</h3>
                  <p className={styles.p}>I've worked for</p>
                </div>
                <div className={styles.mediaContainer}>
                  <img className={styles.media} src={brands} />
                </div>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.goDaddy)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>GoDaddy</h3>
                  <p className={styles.p}>Mini Case Study</p>
                </div>
                <div className={styles.mediaContainer}>
                  <img className={styles.media} src={goDaddy} />
                </div>
                <button className={styles.button} onClick={() => openModal('goDaddy')} type="button">
                  <ArrowDiagonal />
                </button>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.ux)}>
              <div className={styles.tileContent}>
                <div className={styles.headingContainer}>
                  <h3 className={styles.h3}>UX Design</h3>
                  <p className={styles.p}>Examples</p>
                </div>
                <div className={styles.mediaContainer}>
                  <img className={styles.media} src={ux} />
                </div>
                <button className={styles.button} onClick={() => openModal('ux')} type="button">
                  <ArrowDiagonal />
                </button>
              </div>
            </div>
            <div className={classNames(styles.tile, styles.contact)}>
              <div className={classNames(styles.tileContent, styles.centered)}>
                <div>
                  <a className={classNames(styles.avatar, styles.hideOnDesktop)} href="https://www.linkedin.com/in/randymarston/" target="_blank">
                    <img className={styles.avatarImage} src={avatar} />
                  </a>
                  <h2 className={styles.h2}>Want to know more?<br/>Let's talk shop.</h2>
                  <button className={styles.pill} type="button">Contact Me</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <footer className={styles.footer}>&copy; {new Date().getFullYear()} Randy Marston. All Rights Reserved.</footer>
      <div className={classNames(styles.modal, {[styles.modalOpen]: modalOpen})}>
        <div className={styles.modalContent}>
          <button className={styles.close} onClick={closeModal} type="button">
            <Close />
          </button>
          <div className={styles.modalMedias}>
            <div className={styles.modalCarousel} style={{ transform: `translateX(-${currentMedia * 100}%)` }}>
              {modals[currentModal]?.media?.map((media, i) => {
                if (media.endsWith('mp4')) {
                  return <video className={styles.modalMedia} controls key={`media-${i}`} playsInline src={media} />;
                }
                return <img className={styles.modalMedia} key={`media-${i}`} src={media} />;
              })}
            </div>
            {modals[currentModal]?.media?.length > 1 && (
              <>
                {currentMedia > 0 && (
                  <button className={classNames(styles.control, styles.back)} onClick={() => setCurrentMedia(currentMedia - 1)} type="button">
                    <ArrowLeft />
                  </button>
                )}
                {currentMedia < modals[currentModal]?.media?.length - 1 && (
                  <button className={classNames(styles.control, styles.next)} onClick={() => setCurrentMedia(currentMedia + 1)} type="button">
                  <ArrowRight />
                </button>
                )}
              </>
            )}
          </div>
          <div className={styles.modalText}>
            <div className={styles.modalHeading}>{modals[currentModal].heading}</div>
            {modals[currentModal].paragraphs && (
              <div className={styles.modalParagraphs}>
                {modals[currentModal].paragraphs.map((paragraph, i) => (
                  <div className={styles.modalParagraph} key={`paragraph-${i}`}>
                    {paragraph.title && (
                      <div className={styles.modalTitle}>{paragraph.title}</div>
                    )}
                    {paragraph.description}
                  </div>
                ))}
              </div>
            )}
            {modals[currentModal]?.kpis && (
              <div className={styles.modalKPIs}>
                {modals[currentModal].kpis.map((kpi, i) => (
                  <div className={styles.modalKPI} key={`kpi-${i}`}>
                    <Bullet /> {kpi}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
